import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import React, { ReactNode } from "react";

type TEvent = DialogCloseEvent | React.MouseEvent<HTMLElement, MouseEvent>;

type IProps = {
    shouldOpen: boolean;
    title?: string;
    children: ReactNode;
    onClose(event: TEvent): void;
    onConfirm(): void;
};

const baseClass = "acl-page-initiate-onboarding";

const ConfirmationDialog: React.FC<IProps> = ({ shouldOpen, children, title, onClose = (): void => undefined, onConfirm = (): void => undefined }) => {
    const onCloseDialog = (event: TEvent): void => onClose(event);
    return (
        shouldOpen && (
            <Dialog onClose={onCloseDialog} className={`${baseClass}__dialog`}>
                <div>
                    <h3>{title}</h3>
                    {children}
                </div>
                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" onClick={onCloseDialog}>
                        Close
                    </Button>
                    <Button themeColor="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                </div>
            </Dialog>
        )
    );
};

export default ConfirmationDialog;
