import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import { useAppUserContext, useIncomingPathContext, useNotificationContext } from "@src/common/Context";
import { emailValidator, passwordValidator } from "@src/common/util";
import { setTokenExpiry, getTokenExpiry } from "@src/common/token";
import { requiredValidator } from "@src/common/util";
import { RECAPTCHA_KEY, shouldDisableCaptcha, shouldDisableWorkers } from "@src/common/config";
import { ILoginResponse } from "@src/common/types";
import axios from "@src/common/http";
import { LabelInput, Icon } from "@components/common";

import "./Login.scss";

const baseClass = "acl-page-auth";

interface ILoginErrorResponse {
    name?: string;
    message?: string;
    isEmailVerified?: string;
    isInternallyAuthorized?: string;
}

const Login: React.FC<{}> = () => {
    const [_appUserData, setAppUserData] = useAppUserContext();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitting2fa, setSubmitting2fa] = useState<boolean>(false);
    const [show2faForm, setShow2faForm] = useState<boolean>(false);
    const [showVerificationStatus, setShowVerificationStatus] = useState<boolean>(false);
    const [verificationError, setVerificationError] = useState<ILoginErrorResponse>({});
    const [showLostPhoneDialog, setShowLostPhoneDialog] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loginResponse, setLoginResponse] = useState<ILoginResponse>();
    const [userId, setUserId] = useState<string>("");
    const recaptchaRef = React.useRef(null);
    const { sendNotification } = useNotificationContext();
    const navigate = useNavigate();

    const [incomingPath, setIncomingPath] = useIncomingPathContext();
    const { isEmailVerified, isInternallyAuthorized } = verificationError;

    async function handleSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        // const response = await axios.post("/portal/login", { ...formData, recaptcha });
        const response = await axios.post("/login", { ...formData, recaptcha });
        const { status, data } = response;

        if (status === 200) {
            const { authToken, tokenExpiryUTCDate, userDetails } = data;
            localStorage.setItem("authToken", authToken);
            setTokenExpiry(tokenExpiryUTCDate);
            setAppUserData(userDetails);
            setTimeout(() => setTimeout(() => navigate(incomingPath?.length ? incomingPath : "/home")));
            setIncomingPath("");
        } else if (status === 202) {
            setShow2faForm(true);
            setLoginResponse({
                email: data.email,
                hash: data.hash,
            });
        } else {
            if (data.error?.name === "FAILED_AUTHORIZATION_CHECK") {
                setVerificationError(data.error);
                setUserId(data.error.userId);
                setShowVerificationStatus(true);
                setSubmitting(false);
            }
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }

        setSubmitting(false);
    }

    async function handle2faSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting2fa(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const response = await axios.post("/login", { ...formData, ...loginResponse, recaptcha });
        // const response = await axios.post("/portal/login", { ...formData, ...loginResponse, recaptcha });
        // email from 202, hash, token from input, recaptcha
        const { status, data } = response;

        if (status === 200) {
            const { authToken, tokenExpiryUTCDate, userDetails } = data;
            localStorage.setItem("authToken", authToken);
            setTokenExpiry(tokenExpiryUTCDate);
            setAppUserData(userDetails);
            setSubmitting2fa(false);
            setTimeout(() => setTimeout(() => navigate(incomingPath?.length ? incomingPath : "/home")));
            setIncomingPath("");
        } else {
            !shouldDisableCaptcha && recaptchaRef.current.reset();
            setSubmitting2fa(false);
        }
    }

    async function handleLostPhone(): Promise<void> {
        setSubmitting(true);
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();

        const emailResponse = await axios({
            // url: `/portal/lost-phone`,
            url: `/lost-phone`,
            method: "post",
            headers: {
                "content-type": "application/json",
                // Authorize: `x-api-key ${commonApiKey}`,
            },
            data: {
                recaptcha,
                email: loginResponse.email,
            },
        });

        if (emailResponse.status === 200) {
            console.warn("Email sent.");
            sendNotification({
                timeout: 12000,
                type: "success",
                message: "An email was successfully sent to the Client Portal support.",
            });
            !shouldDisableCaptcha && recaptchaRef.current.reset();
            setShowLostPhoneDialog(false);
            setShow2faForm(false);
        } else {
            console.error(emailResponse.data.message || emailResponse.data.error);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
            setShowLostPhoneDialog(false);
        }

        setSubmitting(false);
    }

    const backToLogin = (): void => {
        setShowVerificationStatus(false);
        setVerificationError({});
    };

    const resendEmail = useCallback(async (): Promise<void> => {
        // const response = await axios.get("/portal/resend-verify-email", { params: { userId } });
        const response = await axios.get("/resend-verify-email", { params: { userId } });
        if (response.status === 200) {
            console.warn("Email sent.");
            sendNotification({
                timeout: 12000,
                type: "success",
                message: `We have resent your verification email.`,
            });
        }
    }, [userId]);

    useEffect(() => {
        // Ignore workers when running unit tests
        if (!shouldDisableWorkers && getTokenExpiry()) {
            navigate("/home");
        }
    }, []);

    return (
        <main className={`${baseClass} ${baseClass}--login`}>
            {showLostPhoneDialog && (
                <Dialog className={`${baseClass}__prompt-dialog`} title="Lost Phone" onClose={(): void => setShowLostPhoneDialog(false)}>
                    <p>If you click continue, the Client Portal support will be informed and then contact you as soon as possible.</p>
                    <div className={`k-form-buttons ${baseClass}__actions`}>
                        <Button onClick={(): void => setShowLostPhoneDialog(false)}>Cancel</Button>
                        <Button themeColor="primary" onClick={handleLostPhone} disabled={submitting}>
                            {submitting && <Icon name="loading" spacing="right" />}
                            Continue
                        </Button>
                    </div>
                    {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                </Dialog>
            )}
            {!show2faForm &&
                (!showVerificationStatus ? (
                    <Form
                        aria-busy={submitting ? "true" : null}
                        onSubmit={handleSubmit}
                        render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                            <FormElement className={`${baseClass}__form`} noValidate={true}>
                                <h1>Client Portal</h1>
                                <Card className={`${baseClass}__content`}>
                                    <CardBody>
                                        <h3 className={`${baseClass}__title`}>Sign in</h3>
                                        <p className={`${baseClass}__signup-link`}>
                                            or
                                            <Link className={`${baseClass}__link`} to="/signup">
                                                Create New User Profile
                                            </Link>
                                        </p>

                                        <Field
                                            type="email"
                                            name="email"
                                            label="Email Address"
                                            id="txtEmail"
                                            ariaDescribedBy="txtEmailError"
                                            component={LabelInput}
                                            validator={emailValidator}
                                            validityStyles={false}
                                            required={true}
                                            disabled={submitting}
                                            className={`${baseClass}__field`}
                                        />

                                        <div className={`${baseClass}__password-wrapper`}>
                                            <Field
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                label="Password"
                                                id="txtPassword"
                                                ariaDescribedBy="txtPasswordError"
                                                component={LabelInput}
                                                validator={passwordValidator}
                                                validityStyles={false}
                                                required={true}
                                                disabled={submitting}
                                                className={`${baseClass}__field`}
                                            />
                                            <Icon
                                                name={showPassword ? "view" : "view-off"}
                                                className={`${baseClass}__view-icon`}
                                                color="secondary"
                                                onClick={(): void => setShowPassword(!showPassword)}
                                            />
                                        </div>
                                        <Link className={`${baseClass}__link-password`} to="/forgot-password">
                                            Forgot password?
                                        </Link>
                                        <div className={`k-form-buttons ${baseClass}__actions`}>
                                            <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                                {submitting && <Icon name="loading" spacing="right" />}
                                                Sign In
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                            </FormElement>
                        )}
                    />
                ) : (
                    <div className={`${baseClass}__verification-status`}>
                        <Card className={`${baseClass}__content`}>
                            <CardBody>
                                <h3 className={`${baseClass}__title`}>Authorization Verification</h3>
                                <p>
                                    In order to log in to the portal,
                                    {!isEmailVerified && " your email address has to be verified."}
                                    {!isInternallyAuthorized && ` ${!isEmailVerified ? "We also" : "we"} have to validate your authorization internally.`}
                                </p>
                                <ul className={`${baseClass}__status-list`}>
                                    <li className={isInternallyAuthorized && `${baseClass}__checked`}>
                                        <Icon name={isInternallyAuthorized ? "check-1" : "time-clock-circle"} color={isInternallyAuthorized || "warning"} />
                                        {isInternallyAuthorized ? "User has been authorized" : "User has not been authorized yet"}
                                    </li>
                                    <li className={isEmailVerified && `${baseClass}__checked`}>
                                        <Icon name={isEmailVerified ? "check-1" : "time-clock-circle"} color={isEmailVerified || "warning"} />
                                        <div>{isEmailVerified ? "Email address is verified" : "Email address is not verified "}</div>
                                    </li>
                                </ul>
                                {!isInternallyAuthorized && <p>We are working on authorising your account and should be done within the next day or two.</p>}
                                <div className={classNames(`k-form-buttons ${baseClass}__actions`, !isEmailVerified && `${baseClass}__actions-multiple`)}>
                                    {!isEmailVerified && <Button onClick={resendEmail}>Re-send verification email</Button>}
                                    <Button themeColor="primary" onClick={backToLogin}>
                                        Back to Login
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            {show2faForm && (
                <>
                    <Form
                        aria-busy={submitting2fa ? "true" : null}
                        onSubmit={handle2faSubmit}
                        render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                            <FormElement className={`${baseClass}__form`} noValidate={true}>
                                <h1>Client Portal</h1>
                                <Card className={`${baseClass}__content`}>
                                    <CardBody>
                                        <p>Please enter the token of your authentication app here.</p>
                                        <Field
                                            name="token"
                                            label="Two-Factor Authentication"
                                            id="token"
                                            ariaDescribedBy="txtNameError"
                                            component={LabelInput}
                                            validator={requiredValidator}
                                            required={true}
                                            className={`${baseClass}__field`}
                                        />
                                        <a
                                            className={`${baseClass}__lost-phone-link`}
                                            data-testid="lost-phone"
                                            onClick={(): void => setShowLostPhoneDialog(true)}
                                        >
                                            Lost your phone?
                                        </a>
                                        <div className={`k-form-buttons ${baseClass}__actions`}>
                                            <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting2fa}>
                                                {submitting2fa && <Icon name="loading" spacing="right" />}
                                                Log in
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                            </FormElement>
                        )}
                    />
                </>
            )}
        </main>
    );
};

export default Login;
