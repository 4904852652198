import React, { useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "../InitiateOnboarding.scss";
import { Input } from "@progress/kendo-react-inputs";
import { ContactForms, InitialForms } from "./types";
import { initialFormDetails } from "../getInitialForms";

const baseClass = "acl-page-initiate-onboarding";

type Props = {
    state: InitialForms;
    setState: React.Dispatch<React.SetStateAction<InitialForms>>;
};

const StepContacts: React.FC<Props> = ({ state, setState }) => {
    const updateForm = (formName, fieldName, newValue): void => {
        setState(prevState => {
            const form = prevState[formName];
            return {
                ...prevState,
                [formName]: { ...form, [fieldName]: newValue },
            };
        });
    };
    return (
        <Card className={`${baseClass}__overview`}>
            <CardBody>
                <div className={`${baseClass}__formLayout`}>
                    {Object.entries(initialFormDetails)
                        .sort(([firstKey, firstValue], [secondKey, secondValue]) => firstValue.order - secondValue.order)
                        .map(([formName, formLabels]) => (
                            <div key={formName} className={`${baseClass}__formWrapper`}>
                                <div>
                                    <h3>{formLabels.title}</h3>
                                </div>
                                {Object.entries(formLabels.labels).map(([fieldKey, fieldValue], i) => (
                                    <div key={i} className={`${baseClass}__stepContactsFields`}>
                                        <label>{[fieldValue]}</label>
                                        <Input value={state[formName][fieldKey]} onChange={(e): void => updateForm(formName, fieldKey, e.value)} />
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </CardBody>
        </Card>
    );
};

export default StepContacts;
