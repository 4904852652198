import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";

import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import { emailValidator } from "@src/common/util";
import axios from "@src/common/http";
import { LabelInput, Icon } from "@components/common";

import "./ForgotPassword.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";

const baseClass = "acl-page-auth";

const ForgotPassword: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);

    async function handleSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        // const response = await axios.post(`/portal/request-reset-password`, { ...formData, recaptcha });
        const response = await axios.post(`/request-reset-password`, { ...formData, recaptcha });
        const { status, data } = response;

        if (status === 200) {
            setSubmitted(true);
        } else {
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }

        setSubmitting(false);
    }

    function handleBack(): void {
        setSubmitted(false);
        navigate("/home");
    }

    return (
        <main className={`${baseClass} ${baseClass}--request-password`}>
            <Form
                aria-busy={submitting ? "true" : null}
                onSubmit={handleSubmit}
                render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                    <FormElement className={`${baseClass}__form`} noValidate={true}>
                        <h1>Client Portal</h1>
                        {submitted && (
                            <Card className={`${baseClass}__content`}>
                                <CardBody>
                                    <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                    <p>We have sent you a reset link. Please check your inbox.</p>
                                    <div className={`k-form-buttons ${baseClass}__actions`}>
                                        <Button data-testid="back-button" themeColor="primary" type="submit" onClick={handleBack}>
                                            Back to Login
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                        {!submitted && (
                            <Card className={`${baseClass}__content`}>
                                <CardBody>
                                    <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                    <p className={`${baseClass}__signup-link`}>
                                        or
                                        <Link className={`${baseClass}__link`} to="/sign-in">
                                            login to your account
                                        </Link>
                                    </p>
                                    <Field
                                        type="email"
                                        name="email"
                                        label="Email Address"
                                        id="txtEmail"
                                        ariaDescribedBy="txtEmailError"
                                        component={LabelInput}
                                        validator={emailValidator}
                                        validityStyles={false}
                                        required={true}
                                        disabled={submitting}
                                        className={`${baseClass}__field`}
                                    />
                                    <div className={`k-form-buttons ${baseClass}__info`}>
                                        <Icon name="question-circle" />
                                        <p>We’ll send a reset password link to this email address if it matches a valid Acolin Client Portal user profile.</p>
                                    </div>
                                    <div className={`k-form-buttons ${baseClass}__actions`}>
                                        <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                            {submitting && <Icon name="loading" spacing="right" />}
                                            Send
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                        {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                    </FormElement>
                )}
            />
        </main>
    );
};

export default ForgotPassword;
