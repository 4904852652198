import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { subscribe, unsubscribe } from "pubsub-js";
import { IncomingPathContext, useAppUserContext, useNotificationContext } from "@src/common/Context";
import routes from "@src/common/routes";
import { AppLayout } from "@components/containers";
import Reload from "@components/common/Reload";
import NotFound from "@pages/NotFound";
import { usePageTracking } from "@src/common/hooks";
import { queryTokenExpiry } from "@src/common/token";
import { getErrorMessage } from "@src/common/errors";
import { emergencyMessage, isDebug } from "@src/common/config";
import { PSChannel } from "@src/common/types";
import { register } from "@src/common/workers";
import { getTokenExpiry } from "@src/common/token";

import "./App.scss";

const Element: React.FC = () => {
    const [incomingPath, setIncomingPath] = useContext(IncomingPathContext);
    const [appUserData, _setAppUserData] = useAppUserContext();
    const location = useLocation();
    useEffect(() => {
        setIncomingPath(location.pathname + location.search);
    }, []);
    return appUserData.email?.length ? <NotFound /> : <Navigate to="/sign-in" />;
};

const App: React.FC<{}> = () => {
    const { sendNotification } = useNotificationContext();
    const location = useLocation();
    const [appUserData, _setAppUserData] = useAppUserContext();
    const [height, setHeight] = useState(0);
    const navigate = useNavigate();
    const ref = useRef(null);

    let channel = null;

    usePageTracking();

    const handleResize = (): void => {
        setHeight(ref.current.clientHeight);
    };

    useEffect(() => {
        // Register Service Worker
        register({ onUpdate: () => sendNotification({ type: "none", message: <Reload /> }) });

        // Check for User Session expiration
        queryTokenExpiry(result => {
            if (result.minutes === 5 || result.minutes === 15) {
                sendNotification({
                    type: "warning",
                    timeout: 45000,
                    message:
                        `Your login session will expire and you will be logged out automatically ${result.formatted}.\n` +
                        `To prevent this and refresh your session in the meantime, please log out and log in again.`,
                });
            }
        });

        // Listen for HTTP response errors
        channel = subscribe(PSChannel.Error, (msg, data) => {
            sendNotification({
                type: "error",
                timeout: 10000,
                message: <div dangerouslySetInnerHTML={{ __html: getErrorMessage(data) }} />,
            });
            isDebug && console.error(msg, data);
        });
    }, []);

    useEffect(() => {
        if (!getTokenExpiry() && appUserData.email && location.pathname !== "/logout" && location.pathname !== "/sign-in") {
            localStorage.removeItem("authToken");
            navigate("/logout?tokenExpired=true");
        }
    }, [location]);

    useEffect(() => (): void => unsubscribe(channel), []);

    useEffect(() => {
        if (emergencyMessage?.length) {
            setHeight(ref.current.clientHeight);

            window.addEventListener("resize", handleResize);
            return (): void => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, []);

    return (
        <div className="app-wrapper" style={{ paddingTop: height }}>
            {emergencyMessage && (
                <div ref={ref} className="app-emergency-message">
                    {emergencyMessage}
                </div>
            )}
            <AppLayout>
                <Routes>
                    <Route path="/" element={<Navigate to="/sign-in" replace />} />
                    {routes
                        .all()
                        .map(({ path, isPublic = false, component: Component = null }) =>
                            isPublic ? (
                                <Route element={<Component />} path={path as string} key={path} />
                            ) : getTokenExpiry() ? (
                                <Route path={path} element={<Component />} key={path} />
                            ) : (
                                <Route element={<Navigate to="/sign-in" />} key={path} />
                            ),
                        )}
                    <Route path="*" element={<Element />} />
                </Routes>
            </AppLayout>
        </div>
    );
};

export default App;
