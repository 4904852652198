import React, { useEffect, useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "../InitiateOnboarding.scss";
import { FieldWrapper } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { StepAgentForm } from "./types";
import { stepAgentFormDetails } from "../getInitialForms";

const baseClass = "acl-page-initiate-onboarding";

type Props = {
    state: StepAgentForm;
    setState: React.Dispatch<React.SetStateAction<StepAgentForm>>;
};

const StepPayingAgent: React.FC<Props> = ({ state, setState }) => {
    const { form: forms, selected, selectedAgent } = state;

    const updateForm = (fieldName, newValue): void => {
        if (selectedAgent === "Other") {
            setState(prevState => {
                const form = prevState.form;

                return { ...prevState, form: { ...form, [fieldName]: newValue } };
            });
        }
    };

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            selected:
                selectedAgent === "Other" ||
                selectedAgent === "Banque Cantonale Vaudoise (BCV)" ||
                selectedAgent === "Neue Privat Bank AG (NPB)" ||
                selectedAgent === "Banque Cantonale de Genève (BCGE)",
        }));
    }, [selectedAgent]);

    useEffect(() => {
        if (selectedAgent !== "Other") {
            setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    payingAgentName: "",
                    person: "",
                    phone: "",
                    email: "",
                    address: "",
                },
            }));
        }
    }, [selectedAgent]);

    return (
        <Card className={`${baseClass}__overview`}>
            <CardBody>
                <div>
                    The appointment of a Swiss bank acting as a Swiss Paying Agent is required by Swiss law for foreign funds offered in Switzerland. Acolin can
                    assist you with the appointment of the Swiss Paying Agent.
                </div>
                <FieldWrapper className={`${baseClass}__fieldWrapper`}>
                    <p>Choose an Acolin recommended Paying Agent or add your own</p>
                    <DropDownList
                        data={["Banque Cantonale Vaudoise (BCV)", "Neue Privat Bank AG (NPB)", "Banque Cantonale de Genève (BCGE)", "Other"]}
                        className={`${baseClass}__dropdown`}
                        onChange={(e): void => setState(prevState => ({ ...prevState, selectedAgent: e.value }))}
                        defaultValue={state.selectedAgent || "Select..."}
                    />
                </FieldWrapper>
                {selected && (
                    <div className={`${baseClass}__stepPayingAgentFormClass`}>
                        {selectedAgent === "Other" && (
                            <div className={`${baseClass}__stepPayingAgentForm`}>
                                {Object.entries(forms).map(([fieldKey, fieldValue]) => (
                                    <div key={fieldKey} className={`${baseClass}__stepPayingAgentFields`}>
                                        <label>{stepAgentFormDetails[fieldKey]}</label>
                                        <Input value={forms[fieldKey]} onChange={(e): void => updateForm(fieldKey, e.value)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <p className={`${baseClass}__stepPayingAgentMessage`}>
                    For any questions or additional information, please contact us at{" "}
                    <a href="mailto:fundsregulationsch@acolin.com">fundsregulationsch@acolin.com</a> .
                </p>
            </CardBody>
        </Card>
    );
};

export default StepPayingAgent;
