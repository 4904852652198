import React, { useCallback } from "react";
import { Chip } from "@progress/kendo-react-buttons";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "../InitiateOnboarding.scss";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Fund, FundFile, FundType, SingleFund, SingleFundFiles, SubFund, SubFundFiles, UmbrellaFiles, UmbrellaFund } from "../../types";
import DragAndDrop from "@components/common/DragAndDrop";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { format } from "date-fns";
import { API_ENDPOINT } from "@src/common/config";
import axios from "@src/common/http";

const baseClass = "acl-page-initiate-onboarding";

type FundDetailsProps = {
    fund: Fund;
    updateFund: (newFund: Fund) => void;
};

const dateFormat = "yyyy-MM-dd";

const downloadFile = async (file: FundFile): Promise<any> => {
    const { rlsWizardId, externalId } = file || {};
    if (!rlsWizardId || !externalId) {
        console.error(`Missing file info for download.`);
        return;
    }
    const { data } = await axios.get(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/download-file/${externalId}`);
    const { name, buffer } = data;
    const url = window.URL.createObjectURL(new Blob([new Uint8Array(buffer.data)], { type: buffer?.type }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

const UmbrellaDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details = fundDetails as UmbrellaFund;
    const { domicile, fiscalYearEnd, regulatorName, fundOfferingToBoth, inceptionDate, isRegulated, ucits, files } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        ucitsAttestationFiles,
    } = files;

    const updateFiles = useCallback(
        (filesKey: keyof UmbrellaFiles, newFiles): void => {
            const newDetails: UmbrellaFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof UmbrellaFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip text={"Umbrella"} value="chip" fillMode={"solid"} />
            <FieldWrapper>
                <Label>Fund name</Label>
                <Input
                    type="text"
                    placeholder="Enter fund name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fund domicile</Label>
                <Input
                    type="text"
                    placeholder="Enter fund domicile"
                    value={domicile}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFundDetails("domicile", event.value)}
                />
            </FieldWrapper>

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>UCITS</Label>
                <div>
                    <RadioButton name="ucits" checked={ucits} label="Yes" onChange={(event): void => updateFundDetails("ucits", true)} />
                    <RadioButton
                        name="ucits"
                        checked={ucits !== undefined && !ucits}
                        label="No"
                        onChange={(event): void => updateFundDetails("ucits", false)}
                    />
                </div>
            </div>
            <FieldWrapper>
                <Label>Inception Date</Label>
                <DatePicker
                    value={inceptionDate ? new Date(inceptionDate) : null}
                    format={dateFormat}
                    placeholder="Enter inception date"
                    onChange={(e): void => updateFundDetails("inceptionDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fiscal Year-End</Label>
                <DatePicker
                    value={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                    format={dateFormat}
                    placeholder="Enter fiscal year-end"
                    onChange={(e): void => updateFundDetails("fiscalYearEnd", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>Is the fund regulated / supervised by governmental authority?</Label>
                <div>
                    <RadioButton name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => updateFundDetails("isRegulated", true)} />
                    <RadioButton
                        name="isRegulated"
                        checked={isRegulated !== undefined && !isRegulated}
                        label="No"
                        onChange={(): void => updateFundDetails("isRegulated", false)}
                    />
                </div>
            </div>

            {isRegulated && (
                <>
                    <FieldWrapper>
                        <Label>Regulator / Supervisor authority name</Label>
                        <Input
                            type="text"
                            placeholder="Enter regulator / supervisor authority name"
                            value={regulatorName}
                            className={`${baseClass}__inputTitle`}
                            onChange={(event): void => updateFundDetails("regulatorName", event.value)}
                        />
                    </FieldWrapper>
                </>
            )}

            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth}
                    label="The fund shall be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund shall be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </FieldWrapper>
            <div className={`${baseClass}__fileList`}>
                <DragAndDrop
                    docType="PR"
                    label="Latest prospectus / OM / PPM"
                    files={latestProspectusFiles ?? []}
                    setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="AR"
                    label="Latest Annual report"
                    files={latestAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="SAR"
                    label="Latest Semi-Annual Report"
                    files={latestSemiAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    //@TODO CHANGE DOCTYPE
                    docType="OTHER"
                    label="Articles of Association"
                    files={articlesOfAssociationFiles ?? []}
                    setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="ASL"
                    label="List of authorized signatories for the fund and the fund provider"
                    files={authorizedSignatoriesFiles ?? []}
                    setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    //@TODO CHANGE DOCTYPE
                    docType="OTHER"
                    label="UCITS attestation"
                    files={ucitsAttestationFiles ?? []}
                    setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
            </div>
        </div>
    );
};

const SingleFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details: SingleFund = fundDetails as SingleFund;
    const { authorizationDate, fiscalYearEnd, inceptionDate, domicile, files, fundOfferingToBoth, isRegulated, regulatorName, ucits } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        marketingFiles,
        priipsKidsFiles,
        ucitsAttestatioinFiles,
    } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SingleFundFiles, newFiles) => {
            const newDetails: SingleFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SingleFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip text={"Single Fund"} value="chip" fillMode={"solid"} />
            <FieldWrapper>
                <Label>Fund name</Label>
                <Input
                    type="text"
                    placeholder="Enter fund name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fund domicile</Label>
                <Input
                    type="text"
                    placeholder="Enter fund domicile"
                    value={domicile}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFundDetails("domicile", event.value)}
                />
            </FieldWrapper>

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>UCITS</Label>
                <div>
                    <RadioButton name="ucits" checked={ucits} label="Yes" onChange={(): void => updateFundDetails("ucits", true)} />
                    <RadioButton name="ucits" checked={ucits !== undefined && !ucits} label="No" onChange={(): void => updateFundDetails("ucits", false)} />
                </div>
            </div>
            <FieldWrapper>
                <Label>Inception Date</Label>
                <DatePicker
                    value={inceptionDate ? new Date(inceptionDate) : null}
                    format={dateFormat}
                    placeholder="Enter inception date"
                    onChange={(e): void => updateFundDetails("inceptionDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fiscal Year-End</Label>
                <DatePicker
                    value={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                    format={dateFormat}
                    placeholder="Enter fiscal year-end"
                    onChange={(e): void => updateFundDetails("fiscalYearEnd", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>Is the fund regulated / supervised by governmental authority?</Label>
                <div>
                    <RadioButton name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => updateFundDetails("isRegulated", true)} />
                    <RadioButton
                        name="isRegulated"
                        checked={isRegulated !== undefined && !isRegulated}
                        label="No"
                        onChange={(): void => updateFundDetails("isRegulated", false)}
                    />
                </div>
            </div>

            {isRegulated && (
                <>
                    <FieldWrapper>
                        <Label>Regulator / Supervisor authority name</Label>
                        <Input
                            type="text"
                            placeholder="Enter regulator / supervisor authority name"
                            value={regulatorName}
                            className={`${baseClass}__inputTitle`}
                            onChange={(event): void => updateFundDetails("regulatorName", event.value)}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Authorization Date</Label>
                        <DatePicker
                            value={authorizationDate ? new Date(authorizationDate) : null}
                            format={dateFormat}
                            placeholder="Enter authorization date"
                            onChange={(e): void => updateFundDetails("authorizationDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                        />
                    </FieldWrapper>
                </>
            )}

            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth}
                    label="The fund shall be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund shall be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </FieldWrapper>
            <DragAndDrop
                docType="PR"
                label="Latest prospectus / OM / PPM"
                files={latestProspectusFiles ?? []}
                setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="AR"
                label="Latest Annual report"
                files={latestAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="SAR"
                label="Latest Semi-Annual Report"
                files={latestSemiAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                //@TODO CHANGE DOCTYPE
                docType="OTHER"
                label="Articles of Association"
                files={articlesOfAssociationFiles ?? []}
                setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="ASL"
                label="List of authorized signatories for the fund and the fund provider"
                files={authorizedSignatoriesFiles ?? []}
                setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                //@TODO CHANGE DOCTYPE
                docType="OTHER"
                label="Marketing documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="PRP"
                label="PRIIPs - KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                //@TODO CHANGE DOCTYPE
                docType="OTHER"
                label="UCITS attestation"
                files={ucitsAttestatioinFiles ?? []}
                setFiles={newFiles => updateFiles("ucitsAttestatioinFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const SubFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details: SubFund = fundDetails as SubFund;
    const { authorizationDate, files } = details;
    const { marketingFiles, priipsKidsFiles, prospectusSupplementFiles } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SubFundFiles, newFiles) => {
            const newDetails: SubFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SubFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip text={"Sub Fund"} value="chip" fillMode={"solid"} />
            <FieldWrapper>
                <Label>Sub Fund name</Label>
                <Input
                    type="text"
                    placeholder="Enter fund name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Authorization Date</Label>
                <DatePicker
                    value={authorizationDate ? new Date(authorizationDate) : null}
                    format={dateFormat}
                    placeholder="Enter authorization date"
                    onChange={(e): void => updateFundDetails("authorizationDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>
            <DragAndDrop
                docType="PR"
                label="Sub-Fund prospectus supplement"
                files={prospectusSupplementFiles ?? []}
                setFiles={newFiles => updateFiles("prospectusSupplementFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                //@TODO CHANGE DOCTYPE
                docType="OTHER"
                label="Marketing documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="PRP"
                label="PRIIPs - KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const FundDetails: React.FC<FundDetailsProps> = ({ fund: formData, updateFund: setFormData }) => (
    <Card className={`${baseClass}__overview`}>
        <CardBody>
            {formData?.fundType === FundType.UMBRELLA && <UmbrellaDetails fund={formData} updateFund={setFormData} />}
            {formData?.fundType === FundType.SINGLE_FUND && <SingleFundDetails fund={formData} updateFund={setFormData} />}
            {formData?.fundType === FundType.SUBFUND && <SubFundDetails fund={formData} updateFund={setFormData} />}
        </CardBody>
    </Card>
);
export default FundDetails;
