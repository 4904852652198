import React, { useEffect, useState } from "react";
import { publish } from "pubsub-js";
import { NetworkStatus, useLazyQuery, useQuery } from "@apollo/client";
import classNames from "classnames";

import { Card, CardHeader, CardBody, CardFooter } from "@progress/kendo-react-layout";
import { RadioButton, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";

import { getFilenameFromContentDisposition } from "@src/common/util";
import { useAppUserContext } from "@src/common/Context";
import { API_ENDPOINT } from "@src/common/config";
import { ERole, PSChannel } from "@src/common/types";
import axios from "@src/common/http";
import Page, { Title } from "@components/containers/Page";
import { BackToTop, Upload as FileUpload, Icon } from "@components/common";

import DataLoads from "../_components/DataLoads";
import "./RegulatoryData.scss";
import { GET_REGULATORY_LOADS } from "@src/common/graphql";
import { errors } from "@src/common/errors";

const baseClass = "acl-page-regulatory-data";

const RegulatoryData: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [uploadType, setUploadType] = useState<string>("emt");
    const [downloadType, setDownloadType] = useState<string>("emt");
    const [loading, setLoading] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);

    const [getRegulatoryLoads, { loading: loadingLoads, error, data, refetch: refetchLoads, networkStatus }] = useLazyQuery(GET_REGULATORY_LOADS, {
        variables: { companyId: appUser.companyId },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }

        getRegulatoryLoads().catch(error => console.error(error));
    }, []);

    const handleDownload = (): void => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        setDownloading(true);

        axios
            // .get(`${API_ENDPOINT}/portal/fund-data?companyId=${appUser.companyId}&type=${downloadType}`, { responseType: "blob" })
            .get(`${API_ENDPOINT}/fund-data?companyId=${appUser.companyId}&type=${downloadType}`, { responseType: "blob" })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = response.headers["content-disposition"];
                const filename = getFilenameFromContentDisposition(header, `${downloadType}-template.xlsx`);

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        setLoading(loadingLoads || networkStatus === NetworkStatus.refetch);
    }, [loadingLoads, networkStatus]);

    const handleUploadChange = React.useCallback(
        (e: InputChangeEvent): void => {
            setUploadType(e.value);
        },
        [setUploadType],
    );

    const handleDownloadChange = React.useCallback(
        (e: InputChangeEvent): void => {
            setDownloadType(e.value);
        },
        [setDownloadType],
    );

    const classes: string = classNames(baseClass, !appUser.roles.includes(ERole.DataManager) && `${baseClass}--no-upload`);

    return (
        <Page className={classes}>
            <Title className={`${baseClass}__title`}>
                Regulatory Data
                <Tooltip openDelay={100} position="top" anchorElement="target" className={`${baseClass}__tooltip`}>
                    <Icon
                        title={"European MiFID Template (EMT) or European PRIIPs Template (EPT)​"}
                        className={`${baseClass}__tooltip`}
                        name="question-circle"
                    />
                </Tooltip>
            </Title>
            <div className={`${baseClass}__static`}>
                {appUser.roles.includes(ERole.DataManager) && (
                    <Card className={`${baseClass}__static__upload`}>
                        <CardHeader className={`${baseClass}__static__header`}>
                            <h3 className={`${baseClass}__static__title`}>Upload</h3>
                        </CardHeader>
                        <CardBody className={`${baseClass}__static__content`}>
                            <FileUpload
                                // url={`${API_ENDPOINT}/portal/fund-data?companyId=${appUser.companyId}&type=regulatory-${uploadType}`}
                                url={`${API_ENDPOINT}/fund-data?companyId=${appUser.companyId}&type=regulatory-${uploadType}`}
                                extensions={[".xlsx"]}
                                multiple={false}
                                onSuccess={(): void => {
                                    if (!appUser.companyId) {
                                        publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                        return;
                                    }
                                    refetchLoads();
                                }}
                            />
                            <div className={`${baseClass}__upload-options`}>
                                <RadioButton
                                    className={`${baseClass}__radio`}
                                    name="upload"
                                    value="emt"
                                    checked={uploadType === "emt"}
                                    label="EMT"
                                    onChange={handleUploadChange}
                                />
                                <RadioButton
                                    className={`${baseClass}__radio`}
                                    name="upload"
                                    value="ept"
                                    checked={uploadType === "ept"}
                                    label="EPT"
                                    onChange={handleUploadChange}
                                />
                                <RadioButton
                                    className={`${baseClass}__radio`}
                                    name="upload"
                                    value="eet"
                                    checked={uploadType === "eet"}
                                    label="EET"
                                    onChange={handleUploadChange}
                                />
                            </div>
                        </CardBody>
                    </Card>
                )}
                <Card className={`${baseClass}__static__upload`}>
                    <CardHeader className={`${baseClass}__static__header`}>
                        <h3 className={`${baseClass}__static__title`}>
                            Download Latest
                            <br />
                            Regulatory Data Template
                        </h3>
                    </CardHeader>
                    <CardBody className={`${baseClass}__static__content`}>
                        <p>Here you will find the latest valid and checked data file.</p>
                        <div className={`${baseClass}__download-options`}>
                            <RadioButton name="download" value="emt" checked={downloadType === "emt"} label="EMT" onChange={handleDownloadChange} />
                            <RadioButton name="download" value="ept" checked={downloadType === "ept"} label="EPT" onChange={handleDownloadChange} />
                            <RadioButton name="download" value="eet" checked={downloadType === "eet"} label="EET" onChange={handleDownloadChange} />
                        </div>
                    </CardBody>
                    <CardFooter className={`${baseClass}__static__footer`}>
                        <Button themeColor="primary" onClick={handleDownload} disabled={downloading} className={`${baseClass}__static__footer-button`}>
                            {downloading ? (
                                <>
                                    <Icon name="loading" spacing="right" /> Downloading
                                </>
                            ) : (
                                "Download Latest"
                            )}
                        </Button>
                    </CardFooter>
                </Card>
            </div>
            <div className={`${baseClass}__status`}>
                <Card className={`${baseClass}__status__upload`}>
                    <CardHeader className={`${baseClass}__status__title`}>
                        <h3 className={`${baseClass}__card__title`}>Regulatory Data Loads</h3>
                        <Button
                            onClick={(): void => {
                                if (!appUser.companyId) {
                                    publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                    return;
                                }
                                refetchLoads();
                            }}
                        >
                            Refresh
                        </Button>
                    </CardHeader>
                    <CardBody className={`${baseClass}__status__content`}>
                        <DataLoads dataset={data?.loads} loading={loading} />
                    </CardBody>
                </Card>
            </div>
            <BackToTop />
        </Page>
    );
};

export default RegulatoryData;
