import React, { useState, useRef, useEffect, ReactNode, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import Mark from "mark.js";

import { Grid, GridColumn as Column, GridColumnProps, GridCellProps, GridRowClickEvent } from "@progress/kendo-react-grid";
import { getter, process, SortDescriptor } from "@progress/kendo-data-query";
import { CardHeader, CardBody, Card, CardFooter } from "@progress/kendo-react-layout";

import LoadingPanel from "@components/common/LoadingPanel";
import ResponsiveTable from "@components/common/ResponsiveTable";

import "./Notifications.scss";
import axios from "@src/common/http";
import Rtf from "../Rtf";
import { format } from "date-fns";
import { Button } from "@progress/kendo-react-buttons";

const baseClass = "acl-notifications";

type Document = {
    [id: string]: {
        description: string;
        filename: string;
        uid: string;
        url: string;
    };
};

type Area = {
    id: string;
    iso: string;
    name: string;
};

type Notification = {
    id: string;
    subject: string;
    message: string;
    content: string;
    date: string;
    documents?: Document;
    selected?: boolean;
    attachmentId?: string;
    attachmentName?: string;
    overview?: string;
    areas?: Area[];
    countries?: Area[];
    isReleaseNote?: boolean;
};

type State = {
    selected: Notification;
    dataState: {
        sort: SortDescriptor[];
        take: number;
        skip: number;
    };
};

type Props = {
    loading?: boolean;
    notifications?: Notification[];
    compact?: boolean;
    pageable?: boolean;
    className?: string;
    subjectTitle?: string;
    messageTitle?: string;
    children?: ReactNode;
};

const MessageCell: React.FC<GridCellProps> = ({ dataItem }) => <td className={`${baseClass}__cell--message`}>{dataItem.overview}</td>;

const LinkCell: React.FC<GridCellProps> = () => <td className={`${baseClass}__cell--link`}>Read</td>;

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";

const handleDownload = (notificationId: string, attachmentName): void => {
    try {
        axios
            // .get(`/portal/notification-attachment`, {
            .get(`/notification-attachment`, {
                params: {
                    id: notificationId,
                },
                responseType: "blob",
            })
            .then(response => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", attachmentName);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    } catch (error) {
        console.error(error);
    }
};

const Notifications: React.FC<Props> = ({
    loading,
    notifications,
    compact = false,
    pageable,
    className,
    subjectTitle = "Subject",
    messageTitle = "Message",
    children,
}) => {
    const initialState: State = {
        selected: null,
        dataState: {
            sort: [{ field: "date", dir: "desc" }],
            take: pageable ? 10 : 5,
            skip: 0,
        },
    };

    const useQuerySearch = (): URLSearchParams => new URLSearchParams(useLocation().search);
    const query = useQuerySearch();
    const [state, setState] = useState<State>(initialState);
    const [data, setData] = React.useState<Notification[]>([]);
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const notificationRef = useRef(null);

    const [titleFilter, setTitleFilter] = useState("");
    const [selectedAreas, setSelectedAreas] = useState<Area[]>([]);

    const idGetter = getter(DATA_ITEM_KEY);

    const navigate = useNavigate();

    const TitleCell: React.FC<GridCellProps> = ({ dataItem }) => (
        <td className={classNames("title-filter", dataItem.isContracted ? `${baseClass}__contracted` : null)}>{dataItem.subject}</td>
    );

    const columns: GridColumnProps[] = [
        { field: "date", title: "Date", width: 105 },
        { field: "subject", title: subjectTitle, className: `${baseClass}__cell--subject`, cell: TitleCell },
        { field: "message", title: messageTitle, className: `${baseClass}__cell--message`, cell: MessageCell },
        { field: "", title: "", cell: LinkCell, width: 75 },
    ];

    const markTitle = (): void => {
        setTimeout(() => {
            const markTitle = new Mark(document.querySelectorAll(".title-filter"));
            markTitle.unmark({
                done: () => markTitle.mark(titleFilter, { className: `${baseClass}__highlight` }),
            });
        });
    };

    const onDataStateChange = ({ dataState }): void => {
        markTitle();
        setState({ ...state, dataState });
    };

    const scrollToContent = (): void => notificationRef.current.scrollIntoView({ behavior: "smooth" });

    const onRowClick = ({
        dataItem: { id, subject, message, date, content, documents, attachmentId, attachmentName, overview, isReleaseNote },
    }: GridRowClickEvent): void => {
        setState({ ...state, selected: { id, subject, message, date, content, documents, attachmentId, attachmentName, overview } });
        setSelectedState({ [id]: true });

        if (compact) {
            if (isReleaseNote) {
                navigate(`/release-notes?id=${id}`);
            } else {
                navigate(`/notifications?id=${id}`);
            }
        } else {
            scrollToContent();
        }
    };

    const classes = classNames(baseClass, compact && `${baseClass}--compact`, className);

    useEffect(() => {
        if (notifications) {
            const urlId = query.get("id");
            setSelectedState({ [urlId]: true });
            setState(state => ({
                ...state,
                selected: urlId ? notifications?.find(notification => notification.id === urlId) : state.selected,
            }));
        }
    }, [notifications]);

    useEffect(() => {
        if (notifications?.length > 0) {
            setData(notifications.map((dataItem: Notification) => Object.assign({ selected: false }, dataItem)));
        }
    }, [notifications]);

    const filteredData = useMemo(() => {
        if (selectedAreas.length === 0 && !titleFilter) {
            return data;
        }

        const filterByAreas = data.filter(item => {
            const itemAreasIso = item.areas?.map(area => area.iso) ?? [];
            const itemCountriesIso = item.countries?.map(country => country.iso) ?? [];

            const selectedIsos = selectedAreas?.map(area => area.iso);

            const areSelectedAreasInArea = selectedIsos.some(iso => itemAreasIso.includes(iso));
            const areSelectedAreasInCountries = selectedIsos.some(iso => itemCountriesIso.includes(iso));

            return areSelectedAreasInArea || areSelectedAreasInCountries;
        });
        const filterData = filterByAreas.length > 0 ? filterByAreas : data;

        return filterData.filter(item => item.subject.toLowerCase().includes(titleFilter.toLowerCase()));
    }, [data, selectedAreas, titleFilter]);

    useEffect(() => {
        markTitle();
    }, [titleFilter, filteredData]);

    useEffect(() => {
        setState(prevState => {
            const { dataState } = prevState;
            const { take, skip } = initialState.dataState;
            return { ...prevState, dataState: { ...dataState, take, skip } };
        });
    }, [selectedAreas, titleFilter]);

    return (
        <div className={classes}>
            <div className={`${baseClass}__wrapper`}>
                <Card className={`${baseClass}__messages`}>
                    {compact && (
                        <CardHeader>
                            <h3 className={`${baseClass}__title`}>Notifications</h3>
                        </CardHeader>
                    )}
                    <CardBody className={`${baseClass}__list`}>
                        {loading && <LoadingPanel />}
                        <ResponsiveTable>
                            <Grid
                                data={process(
                                    filteredData.map(item => ({
                                        ...item,
                                        date: item.date ? format(new Date(item.date), "yyyy-MM-dd") : null,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                                    })) ?? [],
                                    state.dataState,
                                )}
                                sortable={true}
                                sort={state.dataState.sort}
                                take={state.dataState.take}
                                skip={state.dataState.skip}
                                scrollable="none"
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                pageable={
                                    pageable && {
                                        buttonCount: 5,
                                        info: true,
                                        type: "input",
                                        pageSizes: false,
                                        previousNext: true,
                                    }
                                }
                                onDataStateChange={onDataStateChange}
                                onRowClick={onRowClick}
                                className={`${baseClass}__notifications-table`}
                            >
                                {columns.map(({ field, ...other }) => (
                                    <Column key={field} field={field} {...other} />
                                ))}
                            </Grid>
                        </ResponsiveTable>
                    </CardBody>
                    {compact && (
                        <CardFooter>
                            <Button style={{ fontWeight: 300 }} onClick={(): void => navigate("/notifications")} themeColor="primary">
                                Read more...
                            </Button>
                        </CardFooter>
                    )}
                </Card>
                {children}
            </div>

            {!compact && (
                <div ref={notificationRef}>
                    <Card className={`${baseClass}__content`}>
                        {state.selected && (
                            <>
                                <CardHeader>
                                    <h3 className={`${baseClass}__subject`}>{state.selected.subject}</h3>
                                    <time className={`${baseClass}__date`} dateTime={state.selected.date}>
                                        {format(new Date(state.selected.date), "yyyy-MM-dd")}
                                    </time>
                                </CardHeader>
                                <CardBody>
                                    {state.selected.content ? (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: state.selected.content }} />
                                            <p className={`${baseClass}__boldText`}>Documents:</p>
                                            {state.selected?.documents &&
                                                Object.entries(state.selected.documents).map(([key, value]) => (
                                                    <div key={key}>
                                                        <ul>
                                                            <li>
                                                                <a href={value.url}>{value.filename}</a> - {value.description}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))}
                                        </>
                                    ) : (
                                        <div className={`${baseClass}__body`}>
                                            <Rtf message={state.selected.message} />
                                            {state.selected.attachmentId && (
                                                <div className={`${baseClass}__attachment_div`}>
                                                    <b>Attachment: </b>
                                                    <a
                                                        className={`${baseClass}__attachment_link`}
                                                        onClick={(): void => handleDownload(state.selected.id, state.selected.attachmentName)}
                                                    >
                                                        {state.selected.attachmentName}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </CardBody>
                            </>
                        )}
                        {!state.selected && !loading && (
                            <CardBody>
                                <p className={`${baseClass}__empty`}>No message selected.</p>
                            </CardBody>
                        )}
                    </Card>
                </div>
            )}
        </div>
    );
};

export default Notifications;
