import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as uuid from "uuid";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import "../InitiateOnboarding.scss";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Field, FieldRenderProps, FieldValidatorType, FieldWrapper, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { requiredValidator } from "@src/common/util";
import { Icon, LabelInput } from "@components/common";
import { Fund, FundType, initialSingleFund, initialSubFund, initialUmbrellaFund } from "../../types";
import FundDetails from "./StepFundsDetails";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import classNames from "classnames";
import ConfirmationDialog from "@components/common/ConfirmationDialog/ConfirmationDialog";

const baseClass = "acl-page-initiate-onboarding";

type Parent = {
    id: string;
    name: string;
};

type CreateData = {
    fundType: FundType;
    parent?: Parent;
    name?: string;
};

type CreateDialogProps = {
    fundType: FundType;
    parent?: Parent;
    parentList?: Parent[];
    onClose(event: DialogCloseEvent): void;
    onCreate?(data: CreateData): void;
};

const initialValues: CreateData = {
    fundType: null,
    name: "",
    parent: null,
};

const CreateDialog: React.FC<CreateDialogProps> = ({ fundType, parent, parentList, onClose = (): void => undefined, onCreate }) => {
    const onCloseDialog = (event: DialogCloseEvent): void => onClose(event);
    const nameLabel = `${fundType} Name`;

    const DropDownComponent = useCallback(
        (props: FieldRenderProps): JSX.Element => (
            <DropDownList dataItemKey="id" textField="name" value={props.value} data={parentList} onChange={({ value }): void => props.onChange({ value })} />
        ),
        [parentList, parent],
    );

    const parentValidator: FieldValidatorType = (value: Parent) => (!value ? "Parent must be selected" : undefined);

    return (
        <Dialog className={`${baseClass}`} title="Title" onClose={onCloseDialog}>
            <h3>Enter name of new {fundType}</h3>
            <Form
                initialValues={{ ...initialValues, fundType, parent }}
                onSubmit={(values, e): void => {
                    const { name, parent } = values;
                    const data: CreateData = { name, fundType, parent };
                    onCreate(data);
                }}
                render={(formRenderProps: FormRenderProps): React.ReactElement => (
                    <FormElement noValidate={true}>
                        <div>
                            <Field
                                autoFocus={true}
                                name="name"
                                label={nameLabel}
                                id="txtName"
                                ariaDescribedBy="txtNameError"
                                component={LabelInput}
                                required={true}
                                className={`${baseClass}__field`}
                                validator={requiredValidator}
                            />
                            {fundType === FundType.SUBFUND && (
                                <FieldWrapper>
                                    <Field
                                        name="parent"
                                        label="Umbrella Name"
                                        id="parent"
                                        ariaDescribedBy="txtNameError"
                                        component={DropDownComponent}
                                        required={true}
                                        className={`${baseClass}__field`}
                                        validator={parentValidator}
                                    />
                                </FieldWrapper>
                            )}
                        </div>
                        <div className={`k-form-buttons ${baseClass}__actions`}>
                            <Button themeColor="primary" type="submit" disabled={!formRenderProps.allowSubmit}>
                                Create
                            </Button>
                        </div>
                    </FormElement>
                )}
            />
        </Dialog>
    );
};

type StepFundsProps = {
    funds: Fund[];
    setFunds: (data: Fund[]) => void;
};

type FundsViewProps = {
    funds: Fund[];
    selectedFund?: Fund;
    onCreate: (data: CreateData, openModal?: boolean) => void;
    onScrollTo?: () => void;
    onFundClick?: (fund: Fund) => void;
    onFundDelete: (fundId: string, parentId?: string) => void;
};

type FundProps = {
    fund: Fund;
    parent?: Fund;
    selectedFund?: Fund;
    onCreate?: (data: CreateData, openModal?: boolean) => void;
    onFundClick?: (fund: Fund) => void;
    onDelete: (fundId: string, parentId?: string) => void;
};

const FundComponent: React.FC<FundProps> = ({ fund, parent, selectedFund, onCreate, onFundClick, onDelete }) => {
    const [shouldDeleteModal, setShouldDeleteModal] = useState<boolean>(false);

    const isUmbrella = fund.fundType === FundType.UMBRELLA;
    const isSubFund = fund.fundType === FundType.SUBFUND;
    const isShareClass = fund.fundType === FundType.SHARECLASS;

    const { fundType, name, id } = fund;

    const isSelected = id === selectedFund?.id;

    const classes = ["fund"];
    if (isSubFund || isShareClass) {
        classes.push("childFund");
    }
    classes.push("newFund");
    if (isSelected) {
        classes.push("selectedFund");
    }

    const handleCreate = useCallback(() => {
        if (!onCreate) {
            return;
        }

        const { fundType } = fund;
        console.log("ON CREATE FUND TYPE: ", fundType);
        if (fundType === FundType.SHARECLASS) {
            return;
        } else if (fundType === FundType.UMBRELLA) {
            onCreate({ fundType: FundType.SUBFUND, parent: fund }, true);
        } else {
            onCreate({ fundType: FundType.SHARECLASS, parent: fund }, true);
        }
    }, [fund, parent, onCreate]);

    const createChildButtonLabel: string = useMemo(() => {
        switch (fund.fundType) {
            case FundType.SINGLE_FUND:
            case FundType.SUBFUND:
                return "ADD ISIN";
            case FundType.UMBRELLA:
                return "ADD SUB FUND";
            default:
                return "";
        }
    }, [fund]);

    const mappedClasses = classes.map(item => `${baseClass}__${item}`);
    const hasChildren = fund.children?.length > 0 ?? false;
    const canCreateChild = isUmbrella;

    const handleFundClick = (): void => {
        if (!onFundClick) {
            return;
        }
        onFundClick(fund);
    };

    function handleDeleteClick(): void {
        setShouldDeleteModal(true);
    }

    return (
        <div className={hasChildren && `${baseClass}__fundContainer`}>
            <div className={classNames(`${baseClass}__fundLayout`)}>
                <div className={classNames([...mappedClasses])} onClick={handleFundClick}>
                    <Chip text={fundType} value="chip" fillMode={"solid"} style={{ margin: "0px 10px" }} />
                    {name}
                    {<Chip text={"NEW"} value="chip" fillMode={"solid"} style={{ margin: "0px 10px" }} />}
                </div>
                <div className={classNames(`${baseClass}__actionContainer`)}>
                    {canCreateChild && (
                        <Button themeColor="primary" onClick={handleCreate}>
                            {createChildButtonLabel}
                        </Button>
                    )}
                </div>
                <Icon title="Delete fund" name="trash" spacing="both" className={`${baseClass}__trash`} onClick={() => handleDeleteClick()} />
                <ConfirmationDialog
                    shouldOpen={shouldDeleteModal}
                    onClose={(): void => {
                        setShouldDeleteModal(false);
                    }}
                    onConfirm={(): void => {
                        onDelete(fund.id, parent?.id);
                    }}
                >
                    <div className={`${baseClass}__success-message`}>Are you sure that you want to delete this?</div>
                </ConfirmationDialog>
            </div>

            {hasChildren && (
                <div className={classNames(`${baseClass}__childFundContainer`)}>
                    {fund.children?.map(subfund => (
                        <FundComponent
                            key={subfund.id}
                            fund={subfund}
                            parent={fund}
                            onFundClick={onFundClick}
                            selectedFund={selectedFund}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const NewFundsView: React.FC<FundsViewProps> = ({ funds, selectedFund, onCreate, onScrollTo, onFundClick, onFundDelete }) => (
    <Card className={`${baseClass}__overview`}>
        <CardHeader>
            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <div style={{ alignContent: "center", alignItems: "center", margin: "0px auto" }}>
                    <h2 className={`${baseClass}__title`}>Create New</h2>
                </div>
                <div className={classNames(`${baseClass}__createActionContainer`)}>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.SINGLE_FUND }, true)}>
                        Single Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.UMBRELLA }, true)}>
                        Umbrella Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.SUBFUND }, true)}>
                        Sub Fund
                    </Button>
                </div>
            </div>
        </CardHeader>
        <CardBody>
            {funds.map((fund, i) => (
                <div className={`${baseClass}__rowFund`}>
                    <div className={`${baseClass}__fundSize`}>
                        <FundComponent
                            key={fund.id}
                            fund={fund}
                            onCreate={onCreate}
                            selectedFund={selectedFund}
                            onDelete={onFundDelete}
                            onFundClick={onFundClick}
                        />
                    </div>
                </div>
            ))}
        </CardBody>
    </Card>
);

type CreateNew = {
    show: boolean;
    fundType: FundType;
    parent: Parent;
};

const initialCreateNew: CreateNew = {
    show: false,
    fundType: null,
    parent: null,
};

const getAllFundIds = (funds: Fund[]): string[] =>
    funds.flatMap(fund => {
        if (fund.children?.length > 0) {
            return [fund.id, ...getAllFundIds(fund.children)];
        } else {
            return [fund.id];
        }
    });

const StepFunds: React.FC<StepFundsProps> = ({ funds, setFunds }) => {
    const [createNew, setCreateNew] = useState<CreateNew>(initialCreateNew);
    const [selectedFund, setSelectedFund] = useState<Fund>(null);

    const myAcolinDataRef = useRef(null);

    const deleteFund = (fundId: string, parentId?: string): void => {
        if (parentId !== undefined) {
            const updatedFunds = funds.map(fund => {
                if (fund.id === parentId) {
                    const updatedChildren = fund.children.filter(child => child.id !== fundId);
                    return { ...fund, children: updatedChildren };
                }
                return fund;
            });
            setFunds(updatedFunds);
        } else {
            const updatedFunds = funds.filter(fund => fund.id !== fundId);
            setFunds(updatedFunds);
        }
    };

    const handleOnScrollTo = (): void => {
        myAcolinDataRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const updateSelectedFund = useCallback(
        (newFund: Fund) => {
            if (!selectedFund) {
                console.error("No fund is selected!");
                return;
            }
            const updatedFunds = funds.map(fund => {
                if (fund["id"] === selectedFund.id) {
                    return newFund;
                } else if (fund.children) {
                    const childIndex = fund.children.findIndex(child => child.id === selectedFund.id);
                    if (childIndex !== -1) {
                        fund.children[childIndex] = newFund;
                    }
                }
                return fund;
            });
            setFunds(updatedFunds);
            setSelectedFund(newFund);
        },
        [selectedFund, funds, setFunds],
    );

    const onFundClick = useCallback((fund: Fund) => {
        setSelectedFund(fund);
    }, []);

    const onCreate = useCallback(
        (data: CreateData, openModal = false) => {
            const { fundType, name, parent } = data;
            if (openModal) {
                setCreateNew({ show: true, fundType, parent });
                return;
            }

            const newFundId: string = uuid.v4();
            if (fundType === FundType.SINGLE_FUND) {
                const newFund: Fund = { fundType, id: newFundId, name, details: initialSingleFund };
                setFunds([...funds, newFund]);
            } else if (fundType === FundType.UMBRELLA) {
                const newFund: Fund = { fundType, id: newFundId, name, children: [], details: initialUmbrellaFund };
                setFunds([...funds, newFund]);
            } else if (fundType === FundType.SUBFUND) {
                const newParentIndex = funds.findIndex(fund => fund.fundType === FundType.UMBRELLA && fund.id === parent.id);
                if (newParentIndex !== -1) {
                    const newParent: Fund = funds[newParentIndex];
                    const newSubFund: Fund = { fundType, id: newFundId, name, details: initialSubFund };
                    newParent.children.push(newSubFund);
                    const updatedNewFunds = funds.map((fund, i) => (i === newParentIndex ? newParent : fund));
                    setFunds(updatedNewFunds);
                    setCreateNew(initialCreateNew);
                    return;
                }

                setCreateNew(initialCreateNew);
                return;
            }
            setCreateNew(initialCreateNew);
        },
        [funds],
    );

    const allUmbrellas: Parent[] = useMemo(() => {
        const newUmbrellas = funds.filter(fund => fund.fundType === FundType.UMBRELLA);
        return newUmbrellas.map(umbrella => ({ id: umbrella.id, name: umbrella.name }));
    }, [funds]);

    useEffect(() => {
        document.body.style.overflow = createNew.show ? "hidden" : "initial";
    }, [createNew]);

    return (
        <div>
            <div className={`${baseClass}__layoutWrapper`}>
                <NewFundsView
                    onScrollTo={handleOnScrollTo}
                    funds={funds}
                    onCreate={onCreate}
                    onFundClick={onFundClick}
                    selectedFund={selectedFund}
                    onFundDelete={deleteFund}
                />
                <FundDetails fund={selectedFund} updateFund={updateSelectedFund} />
            </div>
            {createNew.show && (
                <CreateDialog
                    fundType={createNew.fundType}
                    parent={createNew.parent}
                    onCreate={onCreate}
                    onClose={(): void => setCreateNew(initialCreateNew)}
                    parentList={allUmbrellas}
                />
            )}
        </div>
    );
};

export default StepFunds;
